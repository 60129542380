import styled from "styled-components";
import ButtonLink from "../../../ui/button-link";

export const Wrapper = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.grey};
  width: 100%;

  @media (max-width:${({ theme: { breakpoints } }): string => breakpoints.phoneValue}px) {
    height: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  margin: auto;
  padding-bottom: 35px;
  padding-top: 10px;
  width: 80%;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    max-width: 600px;
    padding-top: 50px;
    padding-bottom: 100px;
  }

  @media (max-width:${({ theme: { breakpoints } }): string => breakpoints.phoneValue}px) {
    padding: 30px 20px;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;

  @media (max-width:${({ theme: { breakpoints } }): string => breakpoints.phoneValue}px) {
    width: 100%;
  }
`;

export const Button = styled(ButtonLink)`
  width: 100%;
  background-color: red !important;
`;

export const SelectAddressContainer = styled.div`
  margin-top: 50px;
`;

export const SelectText = styled.div`
  margin-bottom: 25px;
`;
