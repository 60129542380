import React, { FC, useCallback, useMemo } from "react";

import {
  AddressInfoContainer,
  AddressInfo,
  AddressName,
  AddressRemoveButtonContainer,
} from "../saved-addresses/style";
import { RadioSelected, Radio, AddressContainer } from "./style/address-line";
import { getAddressTypes } from "../confirm-address-modal";

import { ApiAddress } from "../../types/address";
import { setAddressManuallyAdded } from "../../store/shopping-cart/actions";
import useThunkDispatch from "../../hooks/use-thunk-dispatch";
import userLanguage from "../hooks/useLanguage";

interface AddressLineProps extends ApiAddress {
  selectAddress: (
    id: string,
    number: string,
    street: string,
    postalCode: string,
    city: string,
    country: string,
    lat: number,
    lng: number
  ) => void;
  addressIdSelected: string | null;
}

const AddressLine: FC<AddressLineProps> = ({
  id,
  type,
  number,
  street,
  city,
  postalCode,
  country,
  notes,
  lat,
  lng,
  selectAddress,
  addressIdSelected,
}) => {
  const dispatch = useThunkDispatch();
  const handleOnclick = useCallback((): void => {
    selectAddress(id, number, street, postalCode, city, country, lat, lng);
    dispatch(setAddressManuallyAdded(false));
  }, [selectAddress, dispatch, setAddressManuallyAdded]);
  
  const locale = userLanguage();
  const addressTypes = useMemo(() => getAddressTypes(locale), [locale]);

  return (
    <AddressContainer onClick={handleOnclick}>
      <AddressInfoContainer>
        <AddressName>{addressTypes[type]}</AddressName>
        <AddressInfo>{`${number} ${street} ${city}`}</AddressInfo>
        <AddressInfo>{`${notes}`}</AddressInfo>
      </AddressInfoContainer>
      <AddressRemoveButtonContainer>
        <Radio>{id === addressIdSelected && <RadioSelected />}</Radio>
      </AddressRemoveButtonContainer>
    </AddressContainer>
  );
};

export default AddressLine;
