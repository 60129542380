import styled from 'styled-components';

export const AddressContainer = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.white};
  padding: 12px 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
`;

export const Radio = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: solid 1px ${({ theme: { colors } }): string => colors.darkGrey};

  @media (min-width: 1200px) {
    margin-right: 7px;
  }
`;

export const RadioSelected = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 9px;
  background-color: ${({ theme: { colors } }): string => colors.secondary};
`;
