import styled from 'styled-components';

import Button from '../../../ui/button';

export const Container = styled.div`
  flex: 1;
  background-color: ${({ theme: { colors } }): string => colors.grey};
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
`;

export const SpinerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 35px 0;
`

export const ContentContainer = styled.div`
  width: 100%;

  @media ${({ theme: { breakpoints } }): string => breakpoints.tablet} {
    width: 80%;
  }

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 50%;
  }
`;

export const AddressContainer = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.white};
  padding: 12px 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const AddressInfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  width: 20%
`;

export const AddressName = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.primary};
  width: 90%;
`;

export const AddressInfo = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 13px;
  color: ${({ theme: { colors } }): string => colors.primary};
  margin-top: 6px;
  font-family: ${({ theme: { fonts } }): string => fonts.italic};
  width: 90%
`;

export const AddressRemoveButtonContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 10px;
`;

export const AddAddressButton = styled(Button)`
  margin: 15px;
`;

export const DeleteContainer = styled.div`
  cursor: pointer;
`;

export const Title = styled.div`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  color: ${({ theme: { colors } }): string => colors.primary};
  font-size: 18px;
  margin: 36px 0;
  text-align: center;
  font-weight: bold;
`;
