import AddressAutocomplete from "../address-autocomplete";
import ConfirmAddressModal from "../confirm-address-modal";
import { useSelector } from 'react-redux';
import {
  ExtendedGoogleMapsPlace,
  PARIS_COORD,
} from "../../store/address/types";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useState, useCallback, useEffect, FC } from "react";
import { Wrapper, Container, Button, ButtonContainer, SelectAddressContainer, SelectText } from "./style";
import { ApiAddress } from '../../types/address';
import { AppState } from '../../store';
import useThunkDispatch from '../../hooks/use-thunk-dispatch';
import { getAddresses } from '../../store/user/actions';
import { setAddress as setAddressAction } from '../../store/address/actions';
import AddressLine from './address-line';

const DEFAULT_ADDRESS = {
  originalPlace: undefined,
  coordinates: PARIS_COORD,
};

interface Props {
  // is address modified on profile page
  addToProfile?: boolean;
  // callback after checking if the address is valid or not
  onAddressConfirmation: (isAvailable: boolean) => void;
}

const ChangeAddress: FC<Props> = ({ addToProfile, onAddressConfirmation }) => {
  const intl = useIntl();
  const dispatch = useThunkDispatch();
  const [address, setAddress] = useState<ExtendedGoogleMapsPlace>(
    DEFAULT_ADDRESS
  );
  const [confirmAddressModalIsOpen, openAddressModal] = useState<boolean>(
    false
  );
  const [addressIdSelected, setAddressIdSelected] = useState<string | null>(
    null
  );

  const onPlaceChange = useCallback((newPlace: ExtendedGoogleMapsPlace): void => {
    setAddress(newPlace);
    openAddressModal(true);
  }, [setAddress, openAddressModal]);

  const selectAddress = useCallback((id, number, street, postalCode, city, country, lat, lng): void => {
    dispatch(setAddressAction({
      coordinates: {
        lat,
        lng,
      },
      formattedAddress: `${number} ${street}, ${postalCode} ${city}, ${country}`,
      addressComponents: {
        number,
        street,
        postalCode,
        city,
        country,
      }
    }));
    setAddressIdSelected(id);
    onAddressConfirmation(true);
  }, [setAddressAction, setAddressIdSelected]);

  const closeModal = useCallback((): void => {
    openAddressModal(false)
  }, [openAddressModal]);

  const confirmAddress = (isAddressAvailable): void => {
    onAddressConfirmation(isAddressAvailable);
    closeModal();
  }

  const savedAddresses = useSelector<AppState, ApiAddress[]>(state => state.user.addresses);

  useEffect(() => {
    const fetchData = (): void => {
      dispatch(getAddresses());
    };

    fetchData();
  }, [dispatch, getAddresses]);

  return (
    <Wrapper>
      <Container>
        <ConfirmAddressModal
          open={confirmAddressModalIsOpen}
          closeModal={closeModal}
          initialPlace={address}
          onConfirmation={confirmAddress}
          addToProfile={addToProfile}
        />
        <h3>
          <FormattedMessage id={`${addToProfile ? 'address.add' : 'changeAddress.title'}`} />
        </h3>
        <p>
          <FormattedMessage id="changeAddress.text" />
        </p>
        <AddressAutocomplete
          inputId="address-autocomplete"
          onPlaceChange={onPlaceChange}
          placeholder={"162 bis rue Ordener, 75018 Paris, France"}
          border
        />
        {!addToProfile && savedAddresses && savedAddresses.length > 0 && (
          <SelectAddressContainer>
            <SelectText><FormattedMessage id="addresses.selectAddress" /></SelectText>
            {savedAddresses.map(address => (
              <AddressLine selectAddress={selectAddress} addressIdSelected={addressIdSelected} key={address.id} {...address} />
            ))}
          </SelectAddressContainer>
        )}
        <ButtonContainer>
          {addToProfile ? (
            <Button link="/addresses" text={intl.formatMessage({ id: "addresses.back" })} />
          ) : (
            <Button link="/cart" text={intl.formatMessage({ id: "changeAddress.redirect" })} />
          )}
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
};

export default ChangeAddress;
